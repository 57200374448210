<template>
  <div>
    <h3>
      我的导航
      <el-button size="small" type="primary" @click="dataDialogVisible = true"
        >新增导航</el-button
      >
    </h3>
    <div class="table">
      <el-table
        :header-cell-style="{ background: '#f5f6f7' }"
        :data="tableData"
      >
        <el-table-column align="center" prop="date" label="序号" width="100">
          <template slot-scope="scope">{{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="title"
          label="导航名称"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="url"
          label="链接"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="createtime"
          label="创建时间"
        ></el-table-column>
        <el-table-column align="center" prop="zip" label="操作" width="120">
          <template slot-scope="scope">
            <div class="flex">
              <el-tooltip
                class="item"
                @click.native="editProduct(scope.row)"
                effect="dark"
                content="编辑"
                placement="top"
              >
                <p class="table-btn">
                  <i class="el-icon-edit"></i>
                </p>
              </el-tooltip>

              <el-tooltip
                class="item"
                @click.native="delList(scope.row.id)"
                effect="dark"
                content="删除导航"
                placement="top"
              >
                <p class="table-btn">
                  <i class="el-icon-delete-solid"></i>
                </p>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 30, 40, 50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>

    <el-dialog
      :title="con.id ? '编辑导航' : '添加导航'"
      :visible.sync="dataDialogVisible"
      width="500px"
    >
      <div class="from">
        <div class="list-data">
          <div class="list-dialog">
            <div class="list" style="width: 100%">
              <p style="margin-bottom: 10px">
                导航名称
                <span style="color: red; margin-left: 0px; font-weight: 500"
                  >*</span
                >
              </p>
              <el-input
                v-model="con.title"
                placeholder="请输入导航名称"
              ></el-input>
            </div>
          </div>

          <div class="list-dialog">
            <div class="list" style="width: 100%">
              <p style="margin-bottom: 10px">
                跳转链接
                <span style="color: red; margin-left: 0px; font-weight: 500"
                  >*</span
                >
              </p>
              <el-input
                v-model="con.url"
                placeholder="请输入跳转链接"
              ></el-input>
            </div>
          </div>
        </div>
      </div>

      <template #footer>
        <div type="flex" slot="footer" class="dialog-footer">
          <el-button type="info" @click="dataDialogVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="submit()">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
  
  <script>
// import header from "@/components/header";
// import { getList, deleteList } from "@/api/collect";
import { addList, updateList, getList, deleteList } from "@/api/user";
export default {
  data() {
    return {
      total: 0,
      pageNum: 1,
      pageSize: 10,
      tableData: [],
      title: "",
      con: {},
      dataDialogVisible: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    handleCurrentChange(e) {
      this.pageNum = e;
      this.getList();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.getList();
    },
    editProduct(list) {
      this.con = JSON.parse(JSON.stringify(list));
      this.dataDialogVisible = true;
    },
    submit() {
      if (this.con.id) {
        updateList(this.con.id, this.con).then((res) => {
          if (res.code == 200) {
            this.getList();
            this.$message.success("编辑成功");
            this.dataDialogVisible = false;
          }
        });
      } else {
        addList(this.con).then((res) => {
          if (res.code == 200) {
            this.getList();
            this.$message.success("添加成功");
            this.dataDialogVisible = false;
          }
        });
      }
    },
    delList(id) {
      deleteList(id).then((res) => {
        if (res.code == 200) {
          this.getList();
          this.$message.success("删除成功");
        }
      });
    },
    getList() {
      getList(this.pageNum, this.pageSize, this.title).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
  },
};
</script>
  
  <style lang="less" scoped>
h3 {
  height: 50px;
  line-height: 50px;
  color: #000;
  position: relative;
  text-indent: 40px;
  font-size: 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &::after {
    content: "";
    position: absolute;
    width: 5px;
    height: 15px;
    background: #3363ff;
    left: 25px;
    top: 50%;
    transform: translateY(-50%);
  }
  .el-button {
    margin-right: 25px;
  }
}
.table {
  width: 95%;
  margin: auto;
  padding-bottom: 20px;
}
</style>